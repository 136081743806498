import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Contact from './Contact';
import Catering from './Catering';
import BookingPage from './Book';
import Menu from './Menu';

export default function NavLinks() {
  const [isWideScreen, setIsWideScreen] = useState<boolean>(window.innerWidth >= 1024);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(true);
  const [burgerToggleClass, setBurgerToggleClass] = useState<string>('');

  useEffect(() => {
    const handleLoad = () => {
      document.body.classList.add('loaded');
    };
  
    window.addEventListener('load', handleLoad);
  
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);

    if(burgerMenuOpen || burgerToggleClass === ''){
      setBurgerToggleClass('toggled')
    }else{
      setBurgerToggleClass('untoggled')
    }
  };
   return (
    <Router>
      {isWideScreen ? <>
      <div className="navBarDivider"></div>
      <nav className='navBar'>
      <Link to="/">
      <img className='logo' src={logo} alt="Logo"/>
      </Link>
        <ul className='navBarList'>
          <li><NavLink to="/">HEM</NavLink></li>
          <li><NavLink to="/meny">MENY</NavLink></li>
          <li><NavLink to="/catering">CATERING</NavLink></li>
          <li><NavLink to="/boka">BOKA</NavLink></li>
          <li><NavLink to="/kontakt">KONTAKT</NavLink></li>
          <li><NavLink target="_blank" to="https://order.thelocoapp.com/restaurants/CrcWofmISW">BESTÄLL</NavLink></li>
          {/*
          <li><NavLink to="https://www.foodora.com/" target="_blank" rel="noopener noreferrer">DELIVERY</NavLink></li>}
          */}
        </ul>
      </nav>
      </>
      :<>
  <div className="burgerMenuDivider"></div>
  <nav id="burgerMenu" className={`burgerMenu ${burgerToggleClass}`}>
  <Link className="logoLink" to="/">
      <img className='logo' src={logo} alt="Logo"/>
      </Link>
  <div id="burgerIcon" className={burgerToggleClass}>
        <div className="clickArea" onClick={toggleBurgerMenu}></div>
        <div className="line" id="line1"></div>
        <div className="line" id="line2"></div>
  </div>
  <ul className={burgerToggleClass}>
          <li><NavLink onClick={toggleBurgerMenu} to="/">HEM</NavLink></li>
          <li><NavLink onClick={toggleBurgerMenu} to="/meny">MENY</NavLink></li>
          <li><NavLink onClick={toggleBurgerMenu} to="/catering">CATERING</NavLink></li>
          <li><NavLink onClick={toggleBurgerMenu} to="/boka">BOKA</NavLink></li>
          <li><NavLink onClick={toggleBurgerMenu} to="/kontakt">KONTAKT</NavLink></li>
          <li><NavLink onClick={toggleBurgerMenu} target="_blank" to="https://order.thelocoapp.com/restaurants/CrcWofmISW">BESTÄLL</NavLink></li>
          
      </ul>
      </nav>
  </>
    }
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/meny" element={<Menu />} />
          <Route path="/catering" element={<Catering />} />
          <Route path="/boka" element={<BookingPage />} />
          <Route path="/kontakt" element={<Contact />} />
        </Routes>
    </Router>
  );
};