import { Helmet } from "react-helmet";

export default function Catering(){
  return (
    <>
    <Helmet>
        <title>Catering | FUN Dining - Sydostasiatisk Catering i Göteborg, Mölndal</title>
        <meta name="description" content="Beställ catering från FUN Dining i Göteborg och Mölndal. Perfekt för företagsevent, födelsedagar och andra tillställningar. Vi erbjuder smakrik sydostasiatisk mat, både vegetariskt och kött, med möjlighet till live matlagning på plats." />
        <meta name="keywords" content="catering Göteborg, catering Mölndal, sydostasiatisk catering, företagsevent catering, födelsedags catering, kick-off catering, studentfest catering, vegetarisk catering, matlagning på plats" />
        <meta property="og:title" content="Catering - FUN Dining | Sydostasiatisk Catering i Göteborg och Mölndal" />
        <meta property="og:description" content="Beställ sydostasiatisk catering från FUN Dining för ditt företagsevent, födelsedag eller annan tillställning. Vi erbjuder smakrika rätter, både vegetariskt och kött, och möjlighet till live matlagning." />
        <meta property="og:type" content="restaurant" />
        <meta property="og:url" content="https://www.fundining.se/catering" />
        <meta property="og:image" content="https://www.fundining.se/static/media/logo512.04aa126a4371dccf615a.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Restaurant",
            "name": "FUN Dining",
            "description": "Sydostasiatisk catering för företagsevent, födelsedagar och andra tillställningar i Göteborg och Mölndal. Erbjuder vegetariska alternativ och live matlagning på plats.",
            "url": "https://www.fundining.se/catering",
            "image": "https://www.fundining.se/static/media/logo512.04aa126a4371dccf615a.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Bifrostgatan 50A",
              "addressLocality": "Mölndal",
              "addressRegion": "Västra Götaland",
              "postalCode": "431 44",
              "addressCountry": "Sverige"
            },
            "telephone": "+4631463888",
            "openingHours": [
              "Mo-Th 11:00-20:00",
              "Fr 11:00-21:00",
              "Sa 13:00-21:00",
              "Su 13:00-20:00"
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+4631463888",
              "contactType": "Customer Service"
            }
          })}
        </script>
      </Helmet>
    <div className="cateringWrapper">
    <div className="cateringContainer">
      <h1>Beställ catering hos oss!</h1>
      <br/>
      <h2>
  Företagsevent, födelsedagar, kick-off, after work, studentfester - you name it!<br/>
  Välj mellan våra favoriträtter, som i de flesta fall går att få vegetariska.<br/>
  För minst 10 personer.<br/><br/>
  Vi levererar och hämtar upp enligt överenskommelse.<br/>
  Vi erbjuder även personal på plats som lagar delar av rätterna live för en förhöjd upplevelse!<br/>
  Vid avbokning mindre än 24 timmar innan leverans debiteras fullpris för beställningen.<br/><br/>
  Kontakta oss via e-post på info@fundining.se för att få en offert.
    </h2>
    </div>
    </div>
    </>
  )
};
