import FoodImages from './FoodImages';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Menu() {


  return (
    <HelmetProvider>
    <Helmet>
    <title>Meny | FUN Dining - Sydostasiatisk Restaurang i Göteborg, Mölndal</title>
    <meta name="description" content="Upptäck FUN Dining, en livlig sydostasiatisk restaurang i Göteborg och Mölndal. Vår unika gatumat och avslappnade atmosfär erbjuder en oförglömlig matupplevelse. Välkommen till FUN Dining!" />
        <meta name="keywords" content="sydostasiatisk restaurang Göteborg, sydostasiatisk restaurang Mölndal, kinesisk restaurang Göteborg, kinesisk restaurang Mölndal, gatumat Göteborg, gatumat Mölndal, asiatisk, mat, FUN Dining" />
        <meta property="og:title" content="FUN Dining - Sydostasiatisk Restaurang i Göteborg och Mölndal" />
        <meta property="og:description" content="Upptäck FUN Dining, en livlig sydostasiatisk restaurang i Göteborg och Mölndal. Njut av vår unika gatumat och avslappnade atmosfär. Besök vår hemsida för mer information!" />
        <meta property="og:type" content="restaurant" />
        <meta property="og:url" content="https://www.fundining.se/meny" />
        <meta property="og:image" content="https://www.fundining.se/static/media/logo512.04aa126a4371dccf615a.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Restaurant",
            "name": "FUN Dining",
            "image": "https://www.fundining.se/static/media/logo512.04aa126a4371dccf615a.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Bifrostgatan 50A",
              "addressLocality": "Mölndal",
              "addressRegion": "Västra Götaland",
              "postalCode": "431 44",
              "addressCountry": "Sverige"
            },
            "telephone": "+4631463888",
            "url": "https://www.fundining.se/meny",
            "servesCuisine": "Sydostasiatisk",
            "priceRange": "$$",
            "openingHours": [
              "Mo-Th 11:00-20:00",
              "Fr 11:00-21:00",
              "Sa 13:00-21:00",
              "Su 13:00-20:00"
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+4631463888",
              "contactType": "Customer Service"
            }
          })}
        </script>
        </Helmet>
      <div className="menuPageContainer">
        {/* 
      <div className="lunchInfo">
      <h1>LUNCH</h1>
      <p className='pDayLunch'>MÅNDAG - FREDAG</p>
      <p className='pTime'>11:00 - 14:30</p>
    </div>
    */}
    <div className="elfsight-app-46166584-d811-4d13-9c2b-97d6d772b157" data-elfsight-app-lazy></div>
      </div>
      <FoodImages />
    </HelmetProvider>
  );
}