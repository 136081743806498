export default function BookingPage(){
    return(
        <iframe title="booking"src="https://fundining.gastrogate.com/tableplanner" style={{
            width: '100%',
            height: '1200px',
            padding: '0px',
            margin: '0px',
            border: '0px solid #fff'
        }}></iframe>
    )
}