import img1 from '../images/home/FoodImages/1.jpg';
import img2 from '../images/home/FoodImages/2.jpg';
import img3 from '../images/home/FoodImages/3.jpg';
import img4 from '../images/home/FoodImages/4.jpg';
import img5 from '../images/home/FoodImages/5.jpg';
import img6 from '../images/home/FoodImages/6.jpg';


export default function FoodImages(){
    return (
      <div className="foodImagesContainer">
        <div className="foodImages">
            <img src={img1} alt="sallad"/>
            <img src={img2} alt="panerad-mat"/>
            <img src={img3} alt="lax-med-ris"/>
            <img src={img4} alt="clay-pot-mat"/>
            <img src={img5} alt="yakinku"/>
            <img src={img6} alt="unagi-don"/>
        </div>
      </div>
    )
  };
