import GoogleReviews from './GoogleReviews';
import ImageContainer from './ImageContainer';
import Story from './Story';
import funImg from '../images/home/fun.jpg';
import { Helmet } from 'react-helmet';
import logoBlack from '../images/logo512.png';
export default function Home(){
  const show = false
  return (<>
      <Helmet>
      <title>Hem | FUN Dining - Sydostasiatisk Restaurang i Göteborg, Mölndal</title>
        <meta name="description" content="Upptäck FUN Dining, en livlig sydostasiatisk restaurang i Göteborg och Mölndal. Vår unika gatumat och avslappnade atmosfär erbjuder en oförglömlig matupplevelse. Välkommen till FUN Dining!" />
        <meta name="keywords" content="sydostasiatisk restaurang Göteborg, sydostasiatisk restaurang Mölndal, kinesisk restaurang Göteborg, kinesisk restaurang Mölndal, gatumat Göteborg, gatumat Mölndal, asiatisk, mat, FUN Dining" />
        <meta property="og:title" content="FUN Dining - Sydostasiatisk Restaurang i Göteborg och Mölndal" />
        <meta property="og:description" content="Upptäck FUN Dining, en livlig sydostasiatisk restaurang i Göteborg och Mölndal. Njut av vår unika gatumat och avslappnade atmosfär. Besök vår hemsida för mer information!" />
        <meta property="og:type" content="restaurant" />
        <meta property="og:url" content="https://www.fundining.se" />
        <meta property="og:image" content={logoBlack} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Restaurant",
            "name": "FUN Dining",
            "image": "https://www.fundining.se/static/media/logo512.04aa126a4371dccf615a.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Bifrostgatan 50A",
              "addressLocality": "Mölndal",
              "addressRegion": "Västra Götaland",
              "postalCode": "431 44",
              "addressCountry": "Sverige"
            },
            "telephone": "+4631463888",
            "url": "https://www.fundining.se",
            "servesCuisine": "Sydostasiatisk",
            "priceRange": "$$",
            "openingHours": [
              "Mo-Th 11:00-20:00",
              "Fr 11:00-21:00",
              "Sa 13:00-21:00",
              "Su 13:00-20:00"
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+4631463888",
              "contactType": "Customer Service"
            }
          })}
        </script>
    </Helmet>
      <ImageContainer />
      <Story />
      <div className="funImgContainer">
      <img src={funImg} alt="fun-food"/>
      </div>
      {show &&
      <GoogleReviews />
    }
    </>
  )
};
