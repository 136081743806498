import funImg from '../images/contact/FUN.jpg';
import { Helmet } from 'react-helmet';

export default function Contact(){
  return (
      <>
      <Helmet>
        <title>Kontakt | FUN Dining - Sydostasiatisk Restaurang i Göteborg, Mölndal</title>
        <meta name="description" content="Kontakta FUN Dining för bokningar och frågor. Vi ligger på Bifrostgatan 50A i Mölndal, Göteborg. Ring oss på 031-463 888 eller skicka e-post till info@fundining.se. Besök vår sida för mer information." />
        <meta name="keywords" content="kontakt FUN Dining, restaurang kontakt, sydostasiatisk restaurang Göteborg, catering kontakt, bokningar Göteborg, kontaktinformation Mölndal" />
        <meta property="og:title" content="Kontakt - FUN Dining | Sydostasiatisk Restaurang i Göteborg och Mölndal" />
        <meta property="og:description" content="Kontakta FUN Dining för bokningar och frågor. Vi ligger på Bifrostgatan 50A i Mölndal, Göteborg. Ring oss på 031-463 888 eller skicka e-post till info@fundining.se. Besök vår sida för mer information." />
        <meta property="og:type" content="restaurant" />
        <meta property="og:url" content="https://www.fundining.se/kontakt" />
        <meta property="og:image" content="https://www.fundining.se/static/media/logo512.04aa126a4371dccf615a.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Restaurant",
            "name": "FUN Dining",
            "description": "Sydostasiatisk restaurang i Göteborg och Mölndal. Kontakta oss för bokningar och frågor via telefon eller e-post.",
            "url": "https://www.fundining.se/kontakt",
            "image": "https://www.fundining.se/static/media/logo512.04aa126a4371dccf615a.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Bifrostgatan 50A",
              "addressLocality": "Mölndal",
              "addressRegion": "Västra Götaland",
              "postalCode": "431 44",
              "addressCountry": "Sverige"
            },
            "telephone": "+4631463888",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+4631463888",
              "contactType": "Customer Service"
            }
          })}
        </script>
      </Helmet>
  <div className="contactWrapper">
    <div className="contactContainer">
      <div className="contactContent">
      <h1>PLATS+KONTAKT</h1>
      <br/><br/>
      <h2>
      Bifrostgatan 50A,
      <br/>
      431 44 Mölndal,
      <br/>
      Göteborg, Sweden
      <br/><br/>
      031- 463 888
      <br/>
      INFO<span className="emailAt">@</span>FUNDINING.SE
      <br/>
      <a href="https://www.google.com/maps/place/Bifrostgatan+50A,+431+44+M%C3%B6lndal,+Sverige/@57.6506853,11.985818,785m/data=!3m1!1e3!4m6!3m5!1s0x464ff2448d441663:0x84139138068f6a70!8m2!3d57.6508021!4d11.9875617!16s%2Fg%2F11kqnh4svd?hl=sv-SE&entry=ttu" rel="noreferrer" target="_blank"><h2>GOOGLE MAPS</h2></a>
      </h2>
    </div>
    </div>
    <div className="infoContainer">
      <div className="infoContent">
        <div>
        <h1>BOKNINGAR</h1>
        <br/>
        <h2>
        Vi ser fram emot att ta få dina bokningar!
         <br/>
         Tveka inte att kontakta oss på 031-463888 eller via
         <br/>
         e-post på info@fundining.se
         <br/><br/>
         Har du frågor om catering?, Besök <a href="/catering">Catering</a> för mer information.
        </h2>
        </div>
        </div>
      </div>
  </div>
  <div className="funImgContainer">
      <img src={funImg} alt="fun-food"/>
  </div>
    </>
  )
};
