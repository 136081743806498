import './App.css';
import Footer from './components/Footer';
import NavLinks from './components/NavLinks';
export default function App() {

  return (
    <>
      <NavLinks />
      <Footer />
    </>
  );
}