export default function Story(){
    return (
      <div className="storyContainer">
        <div className="storyContent">
          <p>Vad är FUN, egentligen? Det beror på vem du frågar. På engelska betyder ordet roligt, vilket passar perfekt eftersom vårt mål är att vara en genuint rolig sydostasiatisk restaurang. Vi siktar på FUN dining istället för det mer formella "fine dining," och omfamnar en livlig, gatumat-inspirerad upplevelse utan den höga prislappen. På vissa språk betyder ordet helt enkelt ris, vilket är ryggraden i många asiatiska kök. Den mytiska draken, en viktig symbol i denna del av världen, representerar styrka, vitalitet, visdom, lycka, framgång och beskydd. Många historiska ledare har associerat sig med denna kraftfulla varelse. Draken har också en särskild plats i kinesisk astrologi, och 2024, året då vi lanserar, råkar vara Drakens år. Som av en händelse är en av våra grundare, Hugo, också född i Drakens år, vilket vi tycker är passande för en restaurang med stora ambitioner.<br></br>
          FUN är vårt sätt att höja asiatisk gatumat - göra den roligare, smakrikare och mer avslappnad. Välkommen till FUN dining, där sydostasiens mystik och magiska rätter möts. Inspirerade av Drakens år 2024 och dess symbolik av styrka, lycka och visdom, skapar vi en unik kulinarisk upplevelse. Upptäck magin i varje tugga på FUN!</p>
        </div>
      </div>
    )
  };
