export default function Footer(){
  const specialOpenings = false;
    return (
      <div className="footerContainer">
        <div className="footerContent">

          <div className="footerContactContainer">
            {specialOpenings &&
            <div className="specialOpenings">15/8-2024 har vi öppet 11:00-14:30</div>           
            }
            <div className="openingHours">
              <div>
                <p className="pDay">MÅNDAG - TORSDAG</p>
                <p className="pTime">11:00 - 20:00</p>
              </div>
              <div>
                <p className="pDay">FREDAG</p>
                <p className="pTime">11:00 - 21:00</p>
              </div>
              <div>
                <p className="pDay">LÖRDAG</p>
                <p className="pTime">12:00 - 21:00</p>
              </div>
              <div>
                <p className="pDay">SÖNDAG</p>
                <p className="pTime">STÄNGT</p>
              </div>
          </div>

            <div className="footerWrapper">
            <div className="addressList">
            <p>
            BIFROSTGATAN 50A
            <br/>
            431 44 MÖLNDAL
            <br/>
            GÖTEBORG, SVERIGE</p>
          </div>
          <div className="footerContactList">
            <p>INFO@FUNDINING.SE
            <br/>
            031- 463 888
            </p>
            </div>
            <div className="mapIconsWrapper">
      <div className="MapContainer">
      <div id="my-map-canvas">
        <iframe
          title="Google Maps Embed"
          style={{ height: '100%', width: '100%', border: '0' }}
          src="https://www.google.com/maps/embed/v1/place?q=Bifrostgatan+50A,+Mölndal,+Sverige&zoom=14&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          allowFullScreen
        ></iframe>
      </div>
    </div>
    <div className="iconsContainer">
                        <i className="fa-brands fa-square-facebook"></i>
                        <a href="https://www.instagram.com/fundining.se" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                        <i className="fa-brands fa-tiktok"></i>
                        <i className="fa-brands fa-youtube"></i>
                    </div>
            </div>
    </div>
          </div>
          </div>
      </div>
    )
  };
